import React from "react";
import Helmet from "react-helmet";

import {
  RegistrationHeader,
  Button,
  Pricing,
  RegistrationPricing
} from "components/Cohort/styles";
const apply = () => {
  return (
    <>
      <Helmet
        title="Cohort Registration | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "og:title",
            content: "Cohort | Enlight"
          },
          {
            name: "og:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          { name: "og:url", content: "https://enlight.nyc/cohort" },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          },
          {
            name: "twitter:title",
            content: "Cohort | Enlight",
          },
          {
            name: "twitter:description",
            content: "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience.",
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png",
          }
        ]}
      />

      <RegistrationHeader>
        <main>
          <div className="titles">
            <div>
              <h3>Thank you — your application has been submitted and payment has been received.</h3>
              <h4>
                You are now officially enrolled in the program! We'll be in touch with more information via email within 24 hours. 
              </h4>
              <br/>
              <h4>
                  In the meantime, check out all the <a href="https://www.notion.so/7abe7cbf69a44f77bf13d2715e911864?v=e4f7fe1b2876484fa487a5a8fb9811ee">projects</a> our past cohort members have created and follow their progress on <a href="/makers">maker log</a>! 
              </h4>
              <br /> <br />
            </div>
          </div>
        </main>
      </RegistrationHeader>
    </>
  );
};

export default apply;
